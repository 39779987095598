.banner {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: url('../../../public/img/bg-banner.png');
    background-size: 100%;

    @media (max-width: $width-576) {
        height: auto;
        padding: 130px 0 80px 0;
    }

    &:after {
        content: "";
        z-index: -1;
        width: 1500px;
        height: 1500px;
        background: linear-gradient(45deg, $yellow 0%, $yellow 50%, transparent 50.1%);
        transform: rotate(135deg);
        left: 50%;
        margin-left: calc(1500px / 2 * -1);
        display: block;
        left: auto;
        right: calc(-801px - 301px);
        bottom: -782px;
        position: absolute;

        @media (max-width: $width-991) {
            display: none;

        }
    }

    .banner-content {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-flow: wrap;
        padding-top: 70px;
        align-items: center;

        @media (max-width: $width-991) {
            padding-top: 0px;

        }

        @media (max-width: $width-576) {
            height: auto;
        }

        .banner-outer {
            width: 100%;
            display: flex;
            flex-flow: wrap;
            align-items: center;

            .left-panel {
                width: 50%;

                @media (max-width: $width-991) {
                    width: 100%;
                }

                p {
                    font-size: 17px;
                    padding-top: 10px;
                }

                .our-client {
                    width: 100%;
                    position: relative;
                    top: 60px;

                    @media (max-width: $width-576) {
                        top: 40px;
                        text-align: center;
                    }

                    @media (min-width: $width-991) {
                        top: 40px;
                    }

                    @media (min-width: $width-1200) {
                        top: 77px;
                    }

                    @media (min-width: $width-1400) {
                        top: 120px;
                    }

                    h4 {
                        font-size: 18px;
                        font-family: $medium;
                        color: white;

                        @media (max-width: $width-576) {
                            font-size: 14px;

                        }
                    }

                    .client-slider {
                        width: 100%;
                        position: relative;
                        top: 15px;

                        @media (max-width: $width-576) {
                            top: 5px;
                        }

                        .carousel .slide {
                            position: static;
                        }

                        .carousel-indicators {
                            position: absolute;
                            bottom: -22px;
                            margin: 0;
                            left: -119px;

                            @media (max-width: $width-576) {
                                left: 0;
                            }

                            button {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                margin-right: 6x;
                            }

                            button {
                                border: solid 1px white;

                                &.active {
                                    background-color: $bg;
                                    border: solid 1px $yellow;
                                }
                            }
                        }

                        .carousel-caption {
                            position: static;
                        }

                        .carousel-control-next,
                        .carousel-control-prev {
                            display: none;
                        }

                        .items {
                            width: calc(100% / 3);
                            float: left;
                            display: flex;
                            align-items: center;
                            height: 50px;

                            @media (max-width: $width-576) {
                                justify-content: center;
                            }

                            img {
                                max-width: 70px;
                                max-height: 30px;

                                @media (max-width: $width-576) {
                                    max-width: 65px;
                                    max-height: 25px;
                                }

                                @media (min-width: $width-991) {
                                    max-width: 90px;
                                    max-height: 30px;
                                }

                                @media (min-width: $width-1200) {
                                    max-width: 120px;
                                    max-height: 40px;
                                }

                            }
                        }
                    }
                }
            }

            .right-panel {
                width: 50%;
                display: none;

                @media (min-width: $width-991) {
                    padding-left: 20px;
                    padding-top: 20px;
                    display: block;

                }

                @media (min-width: $width-1200) {
                    padding-left: 40px;
                    padding-top: 70px;
                }

                @media (min-width: $width-1400) {
                    padding-top: 100px;
                    padding-left: 70px;

                }

                .imagery-with-content {
                    width: 100%;
                    background-color: black;

                    .hero-image {
                        height: 270px;
                        width: 100%;

                        @media (min-width: $width-991) {
                            height: 200px;
                        }

                        @media (min-width: $width-1200) {
                            height: 290px;
                        }

                        @media (min-width: $width-1400) {
                            height: 350px;
                        }

                        img {
                            object-fit: cover;
                            object-position: center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .desi-1 {
                        width: 100%;
                        padding: 20px;

                        ul {
                            display: flex;
                            flex-flow: wrap;
                            width: 100%;

                            li {
                                font-size: 14px;
                                position: relative;
                                padding-left: 26px;
                                margin-bottom: 15px;
                                width: calc(100% / 3);
                                padding-right: 5px;
                                &.bullet{
                                    &:before{
                                        display: none;
                                    }
                                }

                                &:last-child {
                                    width: 100%;
                                    margin-bottom: 0;
                                }

                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    background-image: url('../../../public/img/aero.png');
                                    background-size: 100%;
                                    width: 18px;
                                    height: 18px;
                                }

                                img {
                                    max-width: 18px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}