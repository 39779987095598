@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900,1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100,200,300,400,500,600,700,800,900&display=swap");

@font-face {
  font-family: "Acumin Pro Condensed";
  src: url("../src/assets/fonts/acumin-pro-condensed-regular.otf");
}
@font-face {
  font-family: "AcuminProCond-Semibold";
  src: url("../src/assets/fonts/AcuminProCond-Semibold.otf");
}
@font-face {
  font-family: "Acumin-Pro";
  src: url("../src/assets/fonts/Acumin-Pro.ttf"), format("ttf");
}
@font-face {
  font-family: "Acumin-Bold";
  src: url("../src/assets/fonts/AcuminPro-Bold.otf"), format("otf");
}

body {
  overflow-x: hidden;
}

/* bottom navbar section start */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AcuminProCond-Semibold" !important;
  margin: 0;
}

h1 span {
  font-family: "AcuminProCond-Semibold" !important;
}
/* p,
a,
span,
li {
  font-family: "Manrope", sans-serif !important;
  line-height: unset;
} */
p,
a,
span,
li {
  font-family: "Acumin-Pro", sans-serif !important;
  line-height: unset;
}

a {
  text-decoration: none !important;
}

div#myHeader {
  padding: 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.sub_menu {
  position: absolute;
  background: #faf6f6;
  left: 0;
  top: 65px;
  z-index: 2;
  padding: 12px;
  width: 100%;
  display: none;
  box-shadow: 0 3px 6px 1px rgba(64, 60, 67, 0.1);
}

.our_links {
  border-top: 1px solid;
  margin-top: 12px;
  padding-top: 12px;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(12, 1fr);
  padding-left: 10px;
}

.menu ul li .our_links div {
  /* background: #036; */
  text-transform: none;
  padding: 8px 10px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  position: relative;
}
.menu ul li .our_links div a {
  /* background: #036; */
  text-transform: none;
  color: #000;
  position: relative;
}
.menu ul li .our_links div a::before {
  content: "";
  /* background-image: url("../public/img/check.png"); */
  background-image: url("../public/img/check.png");
  height: 20px;
  width: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  z-index: 9;
  left: -22px;
  top: 0px;
  background-size: 100%;
  position: absolute;
}
.menu ul li .our_links a:hover {
  color: initial;
}
/* .menu ul li .our_links a img {
  display: none;
  position: absolute;
  margin-right: 10px !important;
} */

.menu ul li .sub_menu_hover {
  display: block;
}

.sub_menu h3 {
  text-align: left;
  font-size: 18px;
  color: #003366;
  line-height: unset;
  font-family: "Poppins", sans-serif;
}

.sub_menu p {
  font-size: 18px;
  text-align: left;
  color: #5d5d5d;
  margin: 0;
  padding: 1px 0 0;
}

.drop_cont {
  display: flex;
  text-align: left;
  gap: 6px;
  margin-top: 8px;
  margin-bottom: 15px;
}
.drop_box.box_3 .drop_cont {
  margin-bottom: 10px;
}
.drop_down_col {
  display: grid;
  gap: 35px;
  margin-top: 12px;
  grid-template-columns: repeat(3, 1fr);
}

/* changes */

.drop_box > h2 {
  text-align: left;
  font-size: 17px;
  color: #003366;
  font-weight: 600;
  border-bottom: 1px solid #003366;
  padding: 10px 0 10px;
}

/* changes */
.pic_text h2 {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.pic_arrow img {
  margin: 0;
}
.drop_box.box_3 .pic_arrow {
  padding-top: 3px;
}
.pic_text p {
  font-size: 16px;
}

.drop_box.box_3 {
  background: #003366;
  padding: 0 30px 18px;
}

.drop_box.box_3 > h2 {
  color: #fff;
  border-color: #fff;
}

.drop_box.box_3 .pic_text p {
  padding: 0;
  color: #fff;
}

.drop_box.box_3 .pic_text {
  width: 100%;
}

.drop_cont .pic_text {
  width: 100%;
}

.pic_arrow {
  width: 24px;
}

.drop_box.box_3 .btn_wrap {
  margin-top: 20px;
}

.menu ul li .drop_box.box_3 .btn_wrap a {
  max-width: 100%;
  font-size: 15px;
  text-transform: none;
  padding: 8px 8px;
  height: auto;
  font-weight: 600;
}

.drop_box.box_3 img {
  margin: 0;
}

.drop_box.box_3 .btn_wrap a {
  max-width: 100%;
  font-size: 15px;
  text-transform: none;
}

.drop_box.box_3 .btn_wrap a:hover {
  color: #fff;
}

.header-section {
  background: #003366;
  position: relative;
}

.header-section .container {
  position: static;
  max-width: 1280px !important;
  margin: 0 auto;
  padding: 0px 15px;
}

.bottom-navbar-homepage {
  max-width: 100%;
}

.bottom-navbar-block-homepage {
  max-width: 1340px;
  margin: 0 auto;
}

.bottom-navbar-section-homepage {
  max-width: 100%;
  margin: 0 auto;
}

.bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  position: unset;
  text-align: center;
}

.bottom-menu-list {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-right: 0px;
  height: 100%;
}

.bottom-menu-list .menu,
.bottom-menu-list .menu ul {
  height: 100%;
  align-items: center;
}

.bottom-menu-list .menu ul li {
  display: grid;
  height: 100%;
  align-items: center;
}

.menu-btn .dropbtn {
  color: var(--cm-white);
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  padding: 8px 0px;
  justify-content: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--cm-medium-blue);
  border: none;
}

.menu ul li .header-item .fa.fa-angle-down {
  font-size: 19px;
}

.menu ul li .header-item {
  color: #f8fafc;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 12px;
  text-transform: uppercase;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Acumin-Pro", sans-serif;
}

.menu ul li:last-child a {
  padding-right: 0;
}

.menu ul li .yellow-btn {
  color: #ffa500;
}

.menu ul li:hover .header-item {
  color: #ffa500;
  cursor: pointer;
}

.menu ul li.active:hover:after {
  content: "";
  width: 31px;
  height: 24px;
  display: block;
  position: absolute;
  background-image: url("../public/img/images/drop-icons.png");
  background-position: center;
  background-size: contain;
  margin: 0 auto;
  text-align: center;
  left: auto;
  right: auto;
  bottom: -6px;
  margin-left: 2.5%;
  background-repeat: no-repeat;
}

.real-menu-toogle {
  display: none;
}

.fa-bars-staggered:before,
.fa-reorder:before,
.fa-stream:before {
  content: "\f550";
  font-size: 20px;
  padding: 0px 20px;
  color: white;
}

/* bottom navbar section end */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* global css start  */

a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1280px !important;
  margin: 0 auto !important;
  padding: 0px 15px !important;
}

/*--------------header css start-------------*/
.login-btns .sign_in {
  background: #ffa500;
  color: #fff !important;
  margin-left: 6px;
}

.login-btns {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 4px;
}

.login-btns .btns {
  border: 1px solid #ffa500;
  color: #ffa500;
  padding: 8px 16px;
  font-family: "Acumin-Pro", sans-serif;
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
}

.login-btns .sign_up:hover {
  border: 1px solid #ffa500;
  color: #fff;
  padding: 8px 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #ffa500;
}

.login-btns .sign_in:hover {
  background: transparent;
  color: #ffa500 !important;
  margin-left: 6px;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0px 0px;
}

section {
  overflow: hidden;
}

.menu ul {
  display: flex;
  margin: 0;
}

.top-menu-list {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-bottom: 30px;
  gap: 40px;
}

.menu-contact p {
  font-size: 18px;
}

.menu-contact span {
  padding-right: 10px;
}

.menu-icons i {
  font-size: 25px;
  margin: 0px 15px;
  color: black;
}

.down-menu-list ul li a {
  text-decoration: none;
  font-size: 14px;
  padding: 2px 8px;
  color: black;
  font-family: "Futura Md BT";
}

/* header css end  */

/* banner-section start  */
.baner-text h1 {
  font-family: "Acumin-Bold", sans-serif !important;
  font-size: 43px;
  font-weight: 800;
  line-height: 58px;
  margin-bottom: 15px;
  color: #000;
}

.baner-text p {
  font-size: 20px;
  max-width: 72%;
  color: #000000;
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  line-height: unset;
}

.baner-text h1 span {
  /* font-family: "Poppins", sans-serif !important; */
  font-family: "Acumin-Bold", sans-serif !important;
  color: #ffa500;
  text-transform: none;
  display: initial;
  font-weight: 800;
}

.btn_wrap {
  margin: 40px 0 0;
  padding: 0;
  display: block;
}

.btn_wrap a {
  font-size: 15px;
  background: #ffa500;
  border: 1px solid #ffa500;
  color: #fff;
  padding: 8px 16px;
  display: flex;
  height: 52px;
  width: 100%;
  max-width: 148px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-transform: uppercase;
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Acumin-Pro", sans-serif !important;
}

.btn_wrap a:hover {
  background-color: transparent;
  border: 1px solid #ffa500;
  color: #ffa500;
}

.banner-section {
  max-width: 100%;
}

.banner-block {
  max-width: 100%;
  margin: 0 auto;
}

.banner-wrapper {
  position: relative;
  height: 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.hero-container {
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  width: auto;
  padding: 94px 15px;
}

.baner-text {
  width: 100%;
}

.banr-im-right {
  width: 75%;
  text-align: right;
}

/* banner-section end  */

/* platform section  */
.platform_sec .container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 15px;
}

.platform_row {
  display: flex;
  padding: 63px 0;
}

.platform_text {
  width: 85%;
  margin-left: 60px;
}

.platform_row .platform_im {
  width: 75%;
}

h1.tittle_main {
  font-weight: 600;
  font-size: 32px;
  letter-spacing: normal;
  line-height: 38px;
  color: #000;
  position: relative;
  z-index: 1;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

h1.tittle_main:after {
  content: "";
  display: block;
  border-bottom: 1px solid #ffa500;
  width: 133px;
  margin-top: 20px;
}

.sub_text {
  display: block;
  margin-top: 40px;
}

.sub_text h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  color: #003366;
  /* font-family: "Manrope", sans-serif !important; */
}

.sub_text p {
  color: #404040;
  font-size: 20px;
  line-height: 28px;
  padding-top: 20px;
}

/*----------questions-section------------*/
.questions_text {
  padding: 83px 0;
}

ul.list_text {
  display: grid;
  margin: 42px 0 0;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  max-width: 70%;
}

ul.list_text li:before {
  content: "";
  background-image: url("../public/img/images/arrow_img.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 16px;
  display: inline-block;
  margin-right: 0;
  position: absolute;
  top: 3px;
  left: 0;
}

ul.list_text li {
  width: 100%;
  padding-left: 35px;
  position: relative;
  font-size: 20px;
  color: #404040;
  font-weight: 500;
  line-height: 22px;
}

/*----------questions-section-end-----------*/

/*----------Invest-section-----------*/
.Invest_sec {
  margin: 72px 0;
}

.Invest_wrap {
  position: relative;
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
}

.Invest_img {
  text-align: right;
}

.Invest_text {
  position: absolute;
  width: 100%;
  max-width: 899px;
  left: 0;
  background: rgba(250, 252, 255, 0.9);
  padding: 40px;
}

.Invest_text ul.list_text {
  max-width: 100%;
  margin-top: 20px;
  display: block;
}

.Invest_text ul.list_text li {
  font-weight: 400;
}

.invest_list {
  display: flex;
  justify-content: space-between;
}

.invest_list ul.list_text li {
  padding-bottom: 16px;
}

.invest_list ul.list_text li:last-child {
  padding-bottom: 0px;
}

/*----------Invest-section-end-----------*/

/*----------sevices-section-----------*/
section.feature-one::after {
  content: "";
  background-image: url("../public/img/images/back-effect.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-size: 100%;
}

section.feature-one {
  background: #f6fff8;
  padding: 75px 15px;
  overflow: hidden;
  position: relative;
}

.feature-one .feature-row {
  display: grid;
  gap: 27px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.feature-one .row::before,
.feature-one .row::after {
  display: none;
}

.wow {
  width: 100%;
  display: flex;
}

section.feature-one h1.tittle_main {
  text-align: center;
}

section.feature-one h1.tittle_main::after {
  text-align: center;
  margin: 20px auto 0;
}

.feature-one__single {
  position: relative;
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 60px 0px rgba(4, 23, 26, 0.08);
  z-index: 1;
  overflow: hidden;
}

.feature-one__hover-shape-1 {
  position: absolute;
  top: 0px;
  right: 0;
  left: 95px;
  height: 29px;
  background-size: cover;
  background-repeat: no-repeat;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.feature-one__shape-1 {
  position: absolute;
  top: 0px;
  right: 0;
  left: 95px;
  height: 29px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  transform-origin: left;
  transform-style: preserve-3d;
  transform: scaleY(1);
  z-index: -1;
}

/* .feature-one__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101px;
    height: 105px;
    background-color: #f0f2f4;
    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
    overflow: hidden;
    z-index: 1;
} */
.feature-one__single:hover .feature-one__hover-shape-1 {
  transform: scaleY(1);
}

.feature-one__single:hover .feature-one__shape-1 {
  transform: scaleY(0);
}

.feature-one__icon::after {
  content: "";
  background-image: url("../public/img/images/shap_serv.png");
  background-repeat: no-repeat;
  height: 106px;
  width: 408px;
  display: block;
  position: absolute;
  top: 0;
  z-index: 0;
}

/* .feature-one__icon::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffa500;
    border-bottom-left-radius: 48px;
    border-bottom-right-radius: 48px;
    background-position: center bottom;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    transform-origin: bottom;
    transform-style: preserve-3d;
    transform: scaleY(0);
    z-index: -1;
}
 */
img.in-hvr {
  display: none;
}

.feature-one__single:hover img.in-hvr {
  display: block;
}

.feature-one__single:hover img.out-hvr {
  display: none;
}

.feature-one__icon {
  width: 110px;
}

.feature-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 52px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
  text-align: center;
  height: 100%;
  max-height: 106px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 111px;
}

.feature-one__single:before {
  content: "";
  display: block;
  border-top: 2px solid #f0f2f4;
  width: 100%;
  position: absolute;
  top: 0;
}

.feature-one__single:hover:before {
  content: "";
  display: block;
  border-top: 2px solid #ffa500;
  width: 100%;
  position: absolute;
  top: 0;
}

.feature-one__single:hover .feature-one__icon::after {
  content: "";
  background-image: url("../public/img/images/hovr_shap.png");
  background-repeat: no-repeat;
  height: 106px;
  width: 408px;
  display: block;
  position: absolute;
  top: 0;
  z-index: 0;
}

.feature-one__content {
  position: relative;
  display: block;
  margin-left: 0;
  padding: 25px 16px 35px;
  width: 76%;
}

.feature-one__content h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.feature-one__content p {
  font-size: 13px;
  color: #5d5d5d;
  margin: 0;
}

.feature-one__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  margin-bottom: 18px;
}

.feature-one__read-more {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.016em;
  color: var(--sonchoy-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-one__title a {
  color: var(--sonchoy-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

/*---------sevices-section-end-----------*/

/*---------video-section-----------*/
.benefits_list ul.list_text {
  display: block;
  margin: 20px 0 0;
  max-width: 100%;
}

.benefits_list h1.tittle {
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  color: #000;
}

.video {
  width: 100%;
}

.benefits_list {
  width: 100%;
}

.benefits_list ul.list_text li {
  padding-bottom: 12px;
}

.benefits_list ul.list_text li:last-child {
  padding-bottom: 0px;
}

.video_row {
  display: flex;
  padding: 72px 0;
  justify-content: space-between;
}

/*---------vedio-section-end-----------*/

/*---------You-are-section-end-----------*/
.You_are_row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.You_are_row .benefits_list {
  max-width: 606px;
  margin: 0 auto;
}

.You_are_row .colm {
  background: #003366;
  padding: 72px 30px;
}

.You_are_row .colm.portfolio {
  background: #fcfeff;
}

.You_are_row .colm.portfolio ul.list_text {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.You_are_row .colm.portfolio ul.list_text li {
  width: 44%;
  font-size: 22px;
}

.You_are_row .colm ul.list_text {
  margin: 40px 0 0;
}

.benefits_list.answers_now h1.tittle_main {
  color: #fff;
}

.benefits_list.answers_now ul.list_text li {
  color: #fff;
  font-weight: 400;
  padding-bottom: 22px;
  font-size: 22px;
}

.benefits_list.answers_now .btn {
  display: block;
  margin-top: 30px;
}

.benefits_list.answers_now .btn a {
  font-size: 15px;
  background: #ffa500;
  color: #fff;
  padding: 8px 16px;
  display: flex;
  height: 52px;
  width: 100%;
  max-width: 234px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

/*---------You-are-section-end-----------*/

/*---------Typical-----------*/
.Typical-sec h1.tittle_main {
  text-align: center;
}

.Typical-sec h1.tittle_main::after {
  margin: 20px auto 0;
}

.Start_Small {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 35px;
}

.Start_Small .colm_sm {
  background: #fff1f1;
  text-align: center;
  padding: 30px 13px;
  align-items: center;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.Start_Small .colm_sm:after {
  content: "";
  background-image: url("../public/img/images/shap_bg.png");
  background-repeat: no-repeat;
  display: block;
  width: 58px;
  height: 56px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.Start_Small .colm_sm h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 27px;
}

.Start_Small .colm_sm h3 {
  margin: 16px 0 0;
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.Start_Small .colm_sm.Adoption {
  background: #faeffa;
}

.Start_Small .colm_sm.Enterprise {
  background: #fff3ee;
}

.Typical-sec {
  padding: 72px 0;
}

.Typical-sec h3.tittle_small {
  text-align: center;
  margin: 30px 0 30px;
  display: block;
  font-size: 22px;
  font-weight: 500;
  color: #000000;
}

.Typical-sec .btn_wrap {
  margin: 55px 0 0;
}

.Typical-sec .btn_wrap a {
  margin: 0 auto;
  max-width: 441px;
}

.Start_Small.sub_block .colm_sb {
  padding: 15px;
  background: #fff;
  box-shadow: 0 3px 6px 1px rgba(64, 60, 67, 0.1);
}

img.mob {
  display: none;
}

.You_want {
  display: block;
  margin-top: 80px;
}

.You_want h3.tittle_small {
  margin-top: 0;
}

.Start_Small.sub_block .colm_sb p:last-child {
  margin-bottom: 0;
}

.Start_Small.sub_block .colm_sb p {
  font-size: 18px;
  color: #404040;
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.Start_Small.sub_block .colm_sb h3 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-top: 18px;
  margin-bottom: 6px;
}

.Start_Small.sub_block .colm_sb p img {
  margin: 8px 0 0;
  padding-right: 8px;
}

/*-----------------------------------*/

/*---------You-needed -----------*/

.You-needed {
  background: #fafafa;
}

.You_needed_row {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  align-items: center;
}

.You_needed_row .You_needed_text {
  width: 60%;
}

.You_needed_row .You_needed_img {
  width: 40%;
}

.You-needed {
  background: #fafafa;
  padding: 72px 0;
}

.You_needed_row .You_needed_text p {
  font-size: 18px;
  line-height: 28px;
  color: #404040;
}

.You_needed_row .You_needed_text h2.sub_hed {
  font-size: 22px;
  color: #003366;
  font-weight: 500;
  margin-top: 30px;
}

/*----------------------------------*/

/*-------------------industries section------------------*/
.industries_row {
  padding: 67px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 63px;
  align-items: center;
}

.industries_text p {
  font-size: 18px;
  line-height: 28px;
  color: #404040;
  margin: 35px 0 0;
  padding: 0;
  display: block;
  font-weight: 400;
}

/*----------------------------------*/

/*-------------------testimoal------------------*/
.testimonal h1.tittle_main {
  margin-top: 0;
}

.testimonal h1.tittle_main::after {
  margin: 20px auto 0;
}

.testimonal {
  text-align: center;
  padding: 80px 0px;
  background: #f7f7f7;
  position: relative;
  overflow: hidden;
}

.testimonal .row {
  margin: 0;
  z-index: 1;
  position: relative;
}

.testimonal:after {
  content: "";
  background-image: url(../public/img/images/back-effect.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.testimonal p {
  color: #393939;
  margin: 0;
  padding-top: 20px;
  font-size: 18px;
  font-weight: 300;
}

.testimonal .carousel {
  padding-top: 26px;
}

/* .carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #ffa500 !important;
} */

.testimonal .carousel-inner .controls.carousel-indicators {
  position: unset;
  text-align: center;
  width: 100%;
  margin: 20px 0 0;
}

.testimonal .carousel-inner .controls.carousel-indicators button.active {
  color: #ffa500;
  background: #ffa500;
  width: 8px;
  border: 0;
  height: 8px;
}

.testimonal .carousel-inner .controls.carousel-indicators button {
  color: #9b9b9b;
  background: #9b9b9b;
  border: 0;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  margin: 0 3px;
}

.carousel-indicators {
  width: inherit;
}

.carousel-indicators .active {
  background-color: #ffa500 !important;
}

.carousel {
  padding-bottom: 50px;
}

.main-mtn {
  position: relative;
}

.right-textinal {
  position: absolute;
  z-index: 99;
  right: 18%;
  top: 33%;
}

.main-mtn h5 {
  font-size: 15px;
  color: #000;
  margin: 19px 0px 5px 0px;
}

.main-mtn strong {
  font-size: 16px;
  color: #727272;
  font-weight: 600;
}

.main-mtn p {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #393939;
  max-width: 947px;
  line-height: 28px;
}

.profile_client {
  background: #fff;
  width: 91px;
  height: 91px;
  border-radius: 100px;
  box-shadow: 0 3px 6px 1px rgba(64, 60, 67, 0.1);
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.main-mtn span {
  color: #000;
}

.main-gool {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gool-tools {
  background: #f4f8ff;
  padding: 40px 0px;
}

.expert-button a {
  background: #000;
  color: #fdf7f4;
  font-size: 20px;
  font-family: Inter, sans-serif !important;
  font-weight: 600;
  padding: 10px 40px;
  border-radius: 50px;
}

.expert-button a:hover {
  color: #fff;
}

/*---------customer-section-----------*/
.customer-sec {
  text-align: center;
  padding: 54px 0;
}

.our_brand {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 40px;
}

.our_brand a {
  display: inline-block;
  border: 1px solid #efefef;
}

.customer-sec {
  text-align: center;
}

.customer-sec h1.tittle_main::after {
  text-align: center;
  margin: 20px auto 0;
}

/*---------customer-section-end----------*/

/*---------download-section-----------*/
.footer-bnr .bnr-warper {
  text-align: center;
  padding: 70px 0;
  background-size: 100%;
}

h1.tittle-sb {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}

.footer-bnr .bnr-warper .btn_wrap {
  text-align: center;
  margin: 22px 0 0;
}

.footer-bnr .bnr-warper .btn_wrap a {
  text-align: center;
  margin: 0 auto;
  max-width: 264px;
}

/*---------download-section-----------*/

/*---------footer-section-----------*/
section.copyright-section {
  background: #003366;
  padding: 10px 0;
}

.copyright-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.our-link a {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Acumin-Pro", sans-serif !important;
}

.our-link a:hover {
  color: #ffa500;
}

.our-link span {
  padding: 0 10px;
}

.copyright-social ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0;
}

.copyright-social ul a {
  width: 37px;
  height: 37px;
  display: flex;
  background: #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.All-Rights p {
  text-align: right;
  color: #fff;
  font-size: 15px;
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Acumin-Pro", sans-serif !important;
  font-weight: 400;
  margin: 0;
}

section.copyright-section {
  background: #003366;
  padding: 26px 0;
}

/*---------footer-section-end-----------*/

/* Media query for responsiveness */
@media screen and (max-width: 1198px) {
  .feature-one__icon {
    width: 110px;
  }

  .header-logo img {
    max-width: 162px;
  }

  .down-menu-list ul li a {
    font-size: 12px;
  }

  .top-menu-list {
    padding-bottom: 10px;
  }

  .menu-icons i {
    font-size: 15px;
  }

  .menu-contact p {
    font-size: 15px;
  }

  .hero-img.banner-wrapper {
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 1365px) {
  .our_links {
    overflow: scroll;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1200px) {
  .You_are_row .benefits_list {
    max-width: 100%;
    margin: 0 auto;
    width: auto;
  }
}

@media screen and (max-width: 1192px) {
  .menu ul li > .header-item {
    font-size: 12px;
    padding: 0px 10px;
  }

  .login-btns .btns {
    font-size: 12px;
  }

  .login-btns .sign_up:hover {
    border: 1px solid #ffa500;
    color: #fff;
    /* padding: 0px 10px; */
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    font-size: 12px;
    line-height: 1.42857143;
    background-color: #ffa500;
  }

  .login-btns .sign_in:hover {
    background: transparent;
    color: #ffa500 !important;
    margin-left: 6px;
  }
}

@media screen and (max-width: 1018px) {
  .bottom-menu-list {
    padding-right: 0px;
  }

  .feature-one__content h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .industries_row,
  .You_needed_row {
    align-items: flex-start;
  }

  .feature-one .feature-row {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    max-width: 600px;
    margin: 40px auto 0;
  }

  ul.list_text {
    max-width: 100%;
  }

  ul.list_text li {
    font-size: 18px;
  }

  h1.tittle_main {
    font-size: 30px;
    line-height: 38px;
  }

  .sub_text h2 {
    font-size: 20px;
    line-height: 24px;
  }

  .sub_text p {
    font-size: 18px;
    line-height: 24px;
  }

  .hero-container .baner-text h1 {
    font-size: 30px;
    line-height: 39px;
  }

  .hero-container .baner-text p {
    font-size: 16px;
    max-width: 100%;
  }

  .menu ul li > .header-item {
    font-size: 10px;
    padding: 0px 10px;
  }

  .menu ul li .header-item .fa.fa-angle-down {
    font-size: 12px;
  }

  .login-btns .btns {
    font-size: 11px;
  }

  .login-btns .sign_up:hover {
    border: 1px solid #ffa500;
    color: #fff;
    /* padding: 0px 10px; */
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    font-size: 11px;
    line-height: 1.42857143;
    background-color: #ffa500;
  }

  .login-btns .sign_in:hover {
    background: transparent;
    color: #ffa500 !important;
    margin-left: 6px;
  }
}

@media screen and (max-width: 886px) {
  .header-logo {
    margin-left: 44px;
  }

  .header-wrapper {
    padding: 15px 0px;
  }

  .real-menu-toogle .fa.fa-bars {
    color: #fff;
  }

  .drop_down_col {
    grid-template-columns: repeat(1, 1fr);
  }

  .our_links {
    overflow: scroll;
    white-space: nowrap;
  }

  .bottom-menu-list .menu {
    position: absolute;
    left: 0;
    width: 100%;
    top: 64px;
    height: auto;
    z-index: 2;
  }

  .bottom-menu-list .menu ul li > .header-item {
    border-bottom: 1px solid #245187;
  }

  .menu ul li .header-item .fa.fa-angle-down {
    font-size: 18px;
  }

  .bottom-menu-list .menu ul li {
    display: block;
  }

  .bottom-menu-list .menu ul li > .header-item {
    font-size: 14px;
    padding: 12px 10px;
    display: block;
  }

  .sub_menu {
    position: relative;
    background: #faf6f6;
    left: 0;
    top: 0px;
    overflow: scroll;
    max-height: 400px;
  }

  .drop_down_col {
    gap: 16px;
  }

  .bottom-menu-list {
    display: none;
  }

  .real-menu-toogle {
    display: block;
    border: 1px solid #fff;
    padding: 6px 10px;
    position: absolute;
    left: 0;
  }

  .bottom-menu-toggle {
    display: none;
  }

  .menu ul {
    list-style: none;
    display: block !important;
    background: #003f8b;
  }

  .menu ul li {
    height: 100%;
    display: grid;
    align-items: center;
  }

  .bottom-navbar-section {
    background-color: transparent;
    max-width: 100%;
    display: block;
  }

  .bottom-container {
    gap: 0px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    position: unset;
    height: auto;
  }

  .header-wrapper {
    position: relative;
  }

  .menu-btn {
    padding-right: 10%;
  }

  .menu ul li.active:hover:after {
    content: "";
    display: none;
  }

  .menu ul li .our_links {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .platform_row {
    padding: 40px 0;
  }

  .You_needed_row .You_needed_img {
    width: 100%;
  }

  .platform_row .platform_im {
    width: 100%;
  }

  .Invest_sec {
    margin: 0;
  }

  .Invest_img {
    height: 100%;
    max-height: 350px;
    overflow: hidden;
  }

  .video {
    width: 100%;
    height: 300px;
    overflow: hidden;
    background: #000;
  }

  .video img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-width: max-content;
    text-align: center;
    margin: 0 auto;
    display: flex;
  }

  img.mob {
    display: block;
  }

  img.desk {
    display: none;
  }

  section.feature-one {
    padding: 40px 15px;
  }

  .You_are_row .colm {
    padding: 40px 30px;
  }

  .video_row,
  .questions_text,
  .Invest_sec,
  .Typical-sec,
  .You-needed,
  .industries_row,
  .testimonal,
  .customer-sec {
    padding: 40px 0px;
  }

  .our_brand {
    grid-template-columns: repeat(3, 1fr);
  }

  .copyright-wrapper {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    gap: 20px;
  }

  .All-Rights p {
    text-align: center;
  }

  .our-link {
    order: 2;
  }

  .All-Rights {
    order: 3;
  }

  .Invest_wrap {
    flex-direction: column-reverse;
  }

  .Invest_text {
    position: unset;
  }

  .video_row {
    flex-direction: column-reverse;
    gap: 30px;
  }

  br {
    display: none;
  }

  .benefits_list h1.tittle {
    font-size: 30px;
  }

  .You_are_row {
    display: block;
  }

  .You_are_row .benefits_list {
    max-width: 100%;
    padding: 0 15px;
  }

  .Start_Small {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }

  .You_needed_row .You_needed_text {
    width: 100%;
  }

  .You_needed_row {
    gap: 30px;
    flex-direction: column-reverse;
  }

  .industries_row {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  .hero-container {
    flex-direction: column-reverse;
    padding: 33px 15px;
  }

  .banr-im-right {
    display: none;
  }

  .platform_row {
    display: block;
  }

  .platform_text {
    width: 100%;
    margin-left: 0;
    margin-top: 31px;
  }
}

@media screen and (max-width: 579px) {
  .bottom-menu-list .menu {
    top: 59px;
  }

  .real-menu-toogle {
    padding: 4px 8px;
  }

  .login-btns .btns {
    padding: 8px 9px;
    font-size: 8px;
  }

  .login-btns .sign_up:hover {
    border: 1px solid #ffa500;
    color: #fff;
    padding: 8px 9px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    font-size: 8px;
    line-height: 1.42857143;
    background-color: #ffa500;
  }

  .login-btns .sign_in:hover {
    background: transparent;
    color: #ffa500 !important;
    margin-left: 6px;
  }

  section.feature-one {
    padding: 40px 0px;
  }

  .hero-container .baner-text h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .btn_wrap a {
    font-size: 12px;
  }

  .Invest_text {
    padding: 20px;
  }

  h1.tittle_main,
  h1.tittle-sb {
    font-size: 24px;
    line-height: 30px;
  }

  ul.list_text li {
    font-size: 16px;
  }

  .feature-one__content h3 {
    font-size: 14px;
  }

  .benefits_list h1.tittle {
    font-size: 24px;
  }

  .You_are_row .colm {
    padding: 40px 0px;
  }

  .benefits_list.answers_now ul.list_text li {
    font-size: 16px;
  }

  .benefits_list.answers_now .btn a {
    font-size: 11px;
    max-width: 100%;
  }

  .You_are_row .colm.portfolio ul.list_text li {
    width: 49%;
    font-size: 16px;
  }

  .Start_Small .colm_sm h3 {
    font-size: 20px;
  }

  .Start_Small .colm_sm h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .Start_Small.sub_block .colm_sb p {
    font-size: 16px;
  }

  .You_needed_row .You_needed_text p,
  .industries_text p,
  .sub_text p {
    font-size: 16px;
    line-height: 24px;
  }

  .main-mtn p {
    max-width: 100%;
    line-height: 24px;
  }

  .right-textinal {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .invest_list {
    flex-direction: column;
  }

  ul.list_text {
    grid-template-columns: repeat(1, 1fr);
  }

  .video {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 360px) {
  .header-logo img {
    max-width: 133px;
  }

  .real-menu-toogle {
    padding: 2px 7px;
  }

  .header-logo {
    margin-left: 38px;
  }

  .login-btns .btns {
    padding: 7px 7px;
    font-size: 9px;
  }

  .login-btns .sign_up:hover {
    border: 1px solid #ffa500;
    color: #fff;
    padding: 7px 7px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    font-size: 9px;
    line-height: 1.42857143;
    background-color: #ffa500;
  }

  .login-btns .sign_in:hover {
    background: transparent;
    color: #ffa500 !important;
    margin-left: 6px;
  }
}
